import AdminDoctorListing from '../pages/Doctor/AdminDoctorListing'
import AdminTemplateAdd from '../pages/Template/AdminTemplateAdd'
import AdminTemplateListing from '../pages/Template/AdminTemplateListing'
import AnswerListing from '../pages/Diseases/AnswerListing.vue'
import AppointmentDetail from '../pages/Appointment/AppointmentDetail'
import AppointmentDetailV2 from '../pages/Appointment/V2/AppointmentDetailV2'
import AppointmentManagement from '../pages/Appointment/AppointmentManagement.vue'
import AppointmentManagementV2 from '../pages/Appointment/V2/AppointmentManagement.vue'
import BloodCountTest from '../pages/Diseases/BloodCountTest'
// import DoctorCircle from '../pages/Doctor/DoctorCircle.vue'
// import DoctorDetailInCircle from '../pages/Doctor/DoctorDetailInCircle.vue'
// import RequestSent from '../pages/Doctor/RequestSent.vue'
// import RequestReceived from '../pages/Doctor/RequestReceived.vue'
import CMSAnswerListing from '../pages/Admin/CMSAnswerListing.vue'
import CMSDiseasesHealthInformation from '../pages/Admin/CMSDiseasesHealthInformation.vue'
import CMSHealthInformation from '../pages/Admin/CMSHealthInformation.vue'
import CMSTopicDetail from '../pages/Admin/TopicDetail.vue'
import CareplanListing from '../pages/Admin/CareplanListing.vue'
import ChatPage from '../pages/Chats/ChatPage'
import CircleChat from '../pages/Circle/CircleChat.vue'
import CircleDocumentsV2 from '../pages/Circle/CircleDocumentsV2.vue'
import CircleList from '../pages/Circle/CircleList.vue'
import ClinicAdd from '../pages/Clinic/ClinicAdd'
import ClinicAppliesListing from '../pages/Admin/ClinicAppliesListing'
import ClinicBankManage from '../pages/Doctor/ClinicBankManage.vue'
import ClinicConsultingRooms from '../pages/Doctor/ClinicConsultingRooms/index.vue'
import ClinicDiseaseTempManage from '../pages/Doctor/ClinicDiseaseTempManage.vue'
import ClinicEdit from '../pages/Clinic/ClinicEdit'
import ClinicInfoManage from '../pages/Doctor/ClinicInfoManage.vue'
import ClinicListing from '../pages/Clinic/ClinicListing'
import ClinicOfDoctor from '../pages/Doctor/ClinicOfDoctor.vue'
import ClinicPersonnelManage from '../pages/Doctor/ClinicPersonnelManage.vue'
import ClinicStatsManage from '../pages/Doctor/ClinicStatsManage.vue'
import ClinicTariffManage from '../pages/Doctor/ClinicTariffManage.vue'
import CmsInformation from '../pages/Admin/CmsInformation'
import ComboManagement from '../pages/Admin/Members/ComboManagement.vue'
import ConcludingList from '../pages/Doctor/ConcludingList.vue'
import ConsultationCreate from '../pages/Diseases/ConsultationCreate.vue'
import ConsultationUpdate from '../pages/Diseases/ConsultationUpdate.vue'
import ContactDetail from '../pages/Contact/ContactDetail.vue'
import ContactList from '../pages/Contact/ContactList.vue'
import ContactListAdmin from '../pages/Admin/ContactListAdmin.vue'
import CreateAppointment from '../pages/Appointment/CreateAppointment'
import CreateDiseaseWithTemplate from '../pages/Diseases/CreateDiseaseWithTemplate.vue'
import CreateMdt from '../pages/HoiChan/CreateMdt.vue'
import CreateMdtDisease from '../pages/HoiChan/CreateMdtDisease.vue'
import CreateMeet from '../pages/Meets/CreateMeet.vue'
// import CircleDetail from '../pages/Circle/CircleDetail.vue'
import CreateNewOrg from '../pages/Account/CreateNewOrg.vue'
import DetailCoverPage from '@/components/PatientVisit/DetailCoverPage.vue'
// import DetailPatientVisit from '../pages/HealthRecords/listPatientVisit/detail/index.vue'
import DetailPatientVisitV2 from '../pages/HealthRecords/listPatientVisit_v2/detail/index.vue'
import DiseaseAdvices from '../pages/Admin/DiseaseAdvices'
import DiseaseCallLogs from '../pages/Admin/DiseaseCallLogs.vue'
import DiseaseDetailForMdt from '../pages/HoiChan/DiseaseDetailForMdt.vue'
import DiseasePacs from '../pages/Admin/PacManagement/DiseasePacs.vue'
import DiseasesActivity from '../pages/Diseases/DiseasesActivity'
import DiseasesCallLogs from '../pages/Diseases/CallLogs.vue'
import DiseasesCreate from '../pages/Diseases/DiseasesCreate.vue'
import DiseasesHealthInformation from '../pages/Diseases/DiseasesHealthInformation.vue'
import DiseasesOverview from '../pages/Diseases/DiseasesOverview'
import DiseasesPrescriptionList from '../pages/Diseases/Prescription/List.vue'
import DoctorPersonListing from '../pages/Person/DoctorPersonListing'
import DoctorProfile from '../pages/DoctorProfile'
import DocumentCareplanListing from '../pages/Admin/DocumentCareplanListing.vue'
import DocumentEchocardiography from '../pages/Diseases/DocumentEchocardiography'
import DocumentManagement from '../pages/Diseases/DocumentManagement'
import DontHaveClinic from '../pages/Doctor/DontHaveClinic.vue'
import EMRVersion from '../pages/ElectronicMedicalRecords/EMRVersion.vue'
import ERMMapView from '../pages/ElectronicMedicalRecords/ERMMapView.vue'
import ERMTreeView from '../pages/ElectronicMedicalRecords/ERMTreeView.vue'
import ElectronicMedicalRecords from '../pages/ElectronicMedicalRecords/index.vue'
import GroupQuestion from '../pages/Admin/GroupQuestion.vue'
import HealthInformation from '../pages/Diseases/HealthInformation'
import HealthRecords from '../pages/HealthRecords/index.vue'
import HodoMember from '../pages/Admin/HodoMember/index.vue'
import HomePage from '../pages/HomePage'
import IndicationListHR from '../pages/HealthRecords/Indication/IndicationList'
import Infomation from '../pages/Account/Infomation.vue'
import InstallDoctorPage from '../pages/InstallDoctorPage'
import InstallPage from '../pages/InstallPage'
// import ClinicManagement from '../pages/Clinic/ClinicManagement.vue'
// import DoctorListing from '../pages/Doctor/DoctorListing'
import InvoiceDetail from '../components/InvoiceDetail.vue'
import InvoiceListing from '../pages/Admin/InvoiceListing.vue'
import InvoicePublicLink from '../pages/PublicPage/InvoicePublicLink.vue'
import InvoicePublicResult from '../pages/PublicPage/InvoicePublicResult.vue'
import KeyManage from '../pages/Admin/KeyManage.vue'
import LichSuPhieuTuVan from '../pages/Diseases/LichSuPhieuTuVan.vue'
import ListBed from '../pages/RoomService/Detail/ListBed.vue'
import RoomManagement from '../pages/RoomManagement/index.vue'
import ListBenhAn from '../pages/BenhAn/ListBenhAn.vue'
import ListMdt from '../pages/HoiChan/ListMdt.vue'
import ListPacs from '../pages/Admin/PacManagement/ListPacs.vue'
import ListPatientVisitV2 from '../pages/HealthRecords/listPatientVisit_v2/index.vue'
// import ListPatientVisit from '../pages/HealthRecords/listPatientVisit/index.vue'
import LiveCircleDetail from '../pages/Circle/LiveCircleDetail.vue'
import Livestream from '../pages/Livestream/index.vue'
import LoginPage from '../pages/LoginPage'
import MDTDDocument from '../pages/HoiChan/MDTDDocument.vue'
import MagicLinkJoinCircle from '../pages/Circle/MagicLinkJoinCircle.vue'
import MagicLinkJoinWsp from '../pages/User/MagicLinkJoinWsp.vue'
import MdtDiseaseDetail from '../pages/HoiChan/MdtDiseaseDetail.vue'
import MdtDiseasePacs from '../pages/HoiChan/MdtDiseasePacs.vue'
import MdtListDisease from '../pages/HoiChan/MdtListDisease.vue'
import MdtRoom from '../pages/HoiChan/MdtRoom.vue'
import MdtRoomUpdate from '../pages/HoiChan/MdtRoomUpdate.vue'
import MedicineAdd from '../pages/Diseases/MedicineAdd'
import MedicineAddCarePlan from '../pages/Admin/MedicineAddCarePlan.vue'
import MedicineCarePlan from '../pages/Admin/MedicineCarePlan.vue'
import MedicineListing from '../pages/Admin/MedicineListing'
import MeetRoomAttachments from '../pages/Meets/MeetRoomAttachments.vue'
import MeetRoomUpdate from '../pages/HoiChan/MeetRoomUpdate.vue'
import Meeting from '../pages/Meeting/index.vue'
import MemberPackOrders from '../pages/Admin/Orders/MemberPackOrders.vue'
import MemberPackageBenefit from '../pages/Admin/Members/MemberPackageBenefit.vue'
import NewCategoryListing from '../pages/Admin/News/NewCategoryListing.vue'
import NewspaperListing from '../pages/Admin/News/NewspaperListing'
import OpenCallPage from '../pages/User/OpenCallPage'
import OperationList from '../pages/Admin/Operation/OperationList.vue'
//
// import TwilioListRoom from '../pages/VideoCall/TwilioListRoom'
//
import OperationPrint from '../components/HoiChan/OperationPrint.vue'
import OrgEmployeeManage from '../pages/Organization/OrgEmployeeManage'
import PackagesManagement from '../pages/Admin/Members/PackagesManagement.vue'
import PasswordReset from '../pages/PasswordReset.vue'
import PatientVisitDetail from '../pages/PatientVisit/detail/index.vue'
// import PatientVisitManagement from '../pages/PatientVisit/PatientVisitManagement'
import PatientVisitLayout from '../pages/PatientVisit/PatientVisitLayout.vue'
import PersonAdminListing from '../pages/Admin/PersonListing.vue'
import PersonDiseasesManagement from '../pages/Diseases/PersonDiseasesManagement'
import PersonMedicineSchedule from '../pages/Diseases/PersonMedicineSchedule.vue'
import PhieuKhamBenhDetail from '../pages/HealthRecords/PhieuKhamBenhDetail'
import Policy from '../pages/TermPolicy/Policy.vue'
import ProductManage from '../pages/Admin/ProductManage.vue'
import ProfileHistory from '../pages/Diseases/ProfileHistory'
import PublicStats from '../pages/Stat/PublicStats'
import RequestListAdmin from '../pages/Admin/RequestListAdmin.vue'
import ResultDetail from '../pages/Diseases/ResultDetail'
import RoomService from '../pages/RoomService/RoomService.vue'
import SearchBarHeader from '../components/Layouts/SearchBarHeader.vue'
import SelectTemplateTest from '../pages/Diseases/SelectTemplateTest'
import ServiceCategoryListing from '../pages/Admin/ServiceCategoryListing.vue'
import ServiceListing from '../pages/Admin/ServiceListing.vue'
import ServicesAgreementWS from '../pages/TermPolicy/ServicesAgreementWS.vue'
import ServicesOrders from '../pages/Admin/Orders/ServicesOrders.vue'
import SessionDetail from '../pages/HoiChan/SessionDetail.vue'
import Setting from '../pages/Setting/Setting'
import SettingBenhAn from '../pages/BenhAn/SettingBenhAn.vue'
import SignUp from '../pages/Account/SignUp.vue'
import StatsList from '../pages/Admin/Stats/StatsList.vue'
import SuccessfulAuthenticationDoctor from '../pages/SuccessfulAuthenticationDoctor.vue'
import TVModeExaminationRegister from '../pages/TVMode/ExaminationRegister.vue'
import DigitalSignature from '../pages/Signature/DigitalSignature.vue'
import TemplateClone from '../pages/Doctor/Templates/TemplateClone.vue'
import TemplateCreate from '../pages/Admin/Templates/TemplateCreate.vue'
import TemplateDetail from '../pages/Admin/Templates/TemplateDetail.vue'
import TemplateList from '../pages/Admin/Templates/TemplateList.vue'
import Term from '../pages/TermPolicy/Term.vue'
import TestCreateOptionsPage from '../pages/Diseases/TestCreateOptionsPage'
import TestDetail from '../pages/Diseases/TestDetail'
import TestInformation from '../pages/Admin/TestInformation.vue'
import TestInformationDetail from '../pages/Admin/TestInformationDetail.vue'
import TestInformationListing from '../pages/Admin/TestInformationListing.vue'
import TestInformationManagement from '../pages/Diseases/TestInformationManagement'
import TodoList from '../pages/Diseases/Todo/TodoList.vue'
import TopicCreate from '../pages/Diseases/TopicCreate.vue'
import TopicDetail from '../pages/Diseases/TopicDetail.vue'
import TopicListing from '../pages/Admin/TopicListing.vue'
import TvMode from '../pages/TVMode/TvMode.vue'
import UpdateAppointment from '../pages/Appointment/UpdateAppointment'
import UpdateHealthInformation from '../pages/Diseases/UpdateHealthInformation'
import UpdateMdtDisease from '../pages/HoiChan/UpdateMdtDisease.vue'
import UptoDatePage from '../components/Layouts/UptoDatePage.vue'
import VideoCallManagement from '../pages/VideoCall/VideoCallManagement'
import VoucherManagement from '../pages/Admin/Members/VoucherManagement.vue'
// import SignUpDoctorPage from '../pages/SignUpDoctorPage.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import WaitingList from '../pages/Diseases/WaitingList'
import WaitingProfileDetails from '../pages/Diseases/WaitingProfileDetails'
import WorkSchedules from '../pages/Appointment/V2/WorkSchedules.vue'
import appUtils from '../utils/appUtils'
import bankAccountListing from '../pages/Admin/bankAccountListing.vue'
import cpn404 from '../pages/page404.vue'
import diseasesListing from '../pages/Admin/diseasesListing.vue'
import permisionsListing from '../pages/Admin/permisionsListing.vue'
import questionListing from '../pages/Admin/questionListing.vue'
import sendMessagesInApp from '../pages/Admin/sendMessagesInApp.vue'
import symptomCategoryListing from '../pages/Admin/symptomCategoryListing.vue'
import tagsListing from '../pages/Admin/tagsListing.vue'
Vue.use(VueRouter)
let routes = [
  // Detail patient
  {
    path: '/tv-mode-examination-register',
    meta: { layout: 'empty', public: false },
    name: 'TVModeExaminationRegister',
    component: TVModeExaminationRegister
  },
  {
    path: '/tv-mode',
    meta: { layout: 'empty', public: false },
    name: 'TvMode',
    component: TvMode
  },
  {
    path: '/cover-page/:id',
    meta: { layout: 'empty', public: false },
    name: 'DetailCoverPage',
    component: DetailCoverPage
  },
  {
    path: '/doctor/searchbar',
    meta: { layout: 'doctor', public: false },
    name: 'SearchBar',
    component: SearchBarHeader
  },
  {
    path: '/doctor/uptodate',
    meta: { layout: 'doctor', public: false },
    name: 'UptoDatePage',
    component: UptoDatePage
  },
  {
    path: '/doctor/uptodate-graphic/:type',
    meta: { layout: 'doctor', public: false },
    name: 'UptoDateGraphic',
    component: () => import('../pages/Uptodate/UptodateGraphic.vue')
  },
  {
    path: '/doctor/uptodate-abstract',
    meta: { layout: 'doctor', public: false },
    name: 'UptoDateAbstract',
    component: () => import('../pages/Uptodate/UptodateAbstract.vue')
  },
  {
    path: '/doctor/uptodate-grade/:id',
    meta: { layout: 'doctor', public: false },
    name: 'UptoDateGrade',
    component: () => import('../pages/Uptodate/UptoDateGrade.vue')
  },
  {
    path: '*',
    meta: { layout: 'empty', public: true },
    name: '404',
    component: cpn404
  },
  {
    path: '/concept',
    meta: { layout: 'user', public: true },
    name: 'Concept',
    component: () => import('../pages/ConceptNewWsOrg/Layout.vue')
  },
  {
    path: '/',
    meta: {
      layout: appUtils.isDoctor()
        ? 'doctor'
        : appUtils.isAdmin()
          ? 'admin'
          : 'user',
      requiredInfo: true
    },
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/manage',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrgEmployeeManage',
    component: OrgEmployeeManage
  },
  {
    path: '/go',
    meta: { layout: 'empty', public: true },
    name: 'InstallPage',
    component: InstallPage
  },
  {
    path: '/bsi',
    meta: { layout: 'empty', public: true },
    name: 'InstallDoctorPage',
    component: InstallDoctorPage
  },
  {
    path: '/statistics',
    meta: { layout: 'empty', public: true },
    name: 'PublicStats',
    component: PublicStats
  },
  {
    path: '/login',
    meta: { layout: 'login', public: true },
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/sign-up',
    meta: { layout: 'login', public: true },
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/create-new-org',
    meta: { layout: 'login', public: true },
    name: 'CreateNewOrg',
    component: CreateNewOrg
  },
  {
    path: '/infomation',
    meta: { layout: 'user', public: true },
    name: 'Infomation',
    component: Infomation
  },
  {
    path: '/new-password',
    meta: { layout: 'login', public: true },
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/services-agreement',
    meta: { layout: 'user', public: true },
    name: 'ServicesAgreementWS',
    component: ServicesAgreementWS
  },
  {
    path: '/meeting/:id',
    meta: { layout: 'empty', public: true },
    name: 'OpenCallPage',
    component: OpenCallPage
  },
  // {
  //   path: '/doctor-sign-up',
  //   meta: {layout: 'login', public: true},
  //   name: 'SignUpDoctorPage',
  //   component: SignUpDoctorPage
  // },
  {
    path: '/email-welcome',
    meta: { layout: 'user', public: true },
    name: 'SuccessfulAuthenticationDoctor',
    component: SuccessfulAuthenticationDoctor
  },
  {
    path: '/invoice/public/:id',
    meta: { layout: 'user', public: true },
    name: 'InvoicePublicLink',
    component: InvoicePublicLink
  },
  {
    path: '/invoice/public-result/:id',
    meta: { layout: 'user', public: true },
    name: 'InvoicePublicResult',
    component: InvoicePublicResult
  },
  {
    path: '/dieu-khoan',
    meta: { layout: 'user', public: true },
    name: 'Term',
    component: Term
  },
  {
    path: '/chinh-sach',
    meta: { layout: 'user', public: true },
    name: 'Policy',
    component: Policy
  },
  {
    path: '/_ws',
    meta: { layout: 'user', public: true },
    name: 'MagicLinkJoinWsp',
    component: MagicLinkJoinWsp
  },
  {
    path: '/_circle',
    meta: { layout: 'user', public: true },
    name: 'MagicLinkJoinCircle',
    component: MagicLinkJoinCircle
  },
  // -------------------------------------------------------- doctor ---------------------------------------------------
  {
    path: '/doctor/consultations-detail/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'PhieuKhamBenhDetail',
    component: PhieuKhamBenhDetail
  },
  {
    path: '/health-records/indications/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'IndicationListHR',
    component: IndicationListHR
  },
  {
    path: '/doctor/persons',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DoctorPersonListing',
    component: DoctorPersonListing
  },
  {
    path: '/doctor/setting',
    meta: { layout: 'doctor', requiredInfo: false, public: false },
    name: 'DoctorSetting',
    component: Setting
  },
  {
    path: '/digital-signature',
    meta: { layout: 'doctor', requiredInfo: false, public: false },
    name: 'DigitalSignature',
    component: DigitalSignature
  },
  {
    path: '/doctor/call-management',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'VideoCallManagement',
    component: VideoCallManagement
  },
  {
    path: '/doctor/clinic',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicOfDoctor',
    component: ClinicOfDoctor
  },
  {
    path: '/doctor/invoice/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'InvoiceDetail',
    component: InvoiceDetail
  },
  {
    path: '/workspace',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'DontHaveClinic',
    component: DontHaveClinic
  },
  // {
  //   path: '/doctor/circle',
  //   meta: {layout: 'doctor', requiredInfo: true, requiredWs: true, public: false},
  //   name: 'DoctorCircle',
  //   component: DoctorCircle
  // },
  // {
  //   path: '/doctor/circle/detail/:id',
  //   meta: {layout: 'doctor', requiredInfo: true, requiredWs: true, public: false},
  //   name: 'DoctorDetailInCircle',
  //   component: DoctorDetailInCircle
  // },
  // {
  //   path: '/doctor/circle/request-sent/:id',
  //   meta: {layout: 'doctor', requiredInfo: true, requiredWs: true, public: false},
  //   name: 'RequestSent',
  //   component: RequestSent
  // },
  // {
  //   path: '/doctor/circle/request-received/:id',
  //   meta: {layout: 'doctor', requiredInfo: true, requiredWs: true, public: false},
  //   name: 'RequestReceived',
  //   component: RequestReceived
  // },
  {
    path: '/doctor/patient-reception',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'AppointmentManagementV2',
    component: AppointmentManagementV2
  },
  {
    path: '/doctor/room-service',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'RoomService',
    component: RoomService
  },
  {
    path: '/room-management',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'RoomManagement',
    component: RoomManagement
  },
  {
    path: '/doctor/room-service/list-bed/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ListBed',
    component: ListBed
  },
  {
    path: '/doctor/patient-reception/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'AppointmentDetailV2',
    component: AppointmentDetailV2
  },
  {
    path: '/doctor/appointment-management',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'AppointmentManagement',
    component: AppointmentManagement
  },
  {
    path: '/doctor/patient-visit',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'PatientVisitManagement',
    component: PatientVisitLayout
  },
  // {
  //   path: '/doctor/appointment/:id',
  //   meta: { layout: 'doctor', requiredInfo: true, requiredWs: true, public: false },
  //   name: 'AppointmentDetail',
  //   component: AppointmentDetail
  // },
  {
    path: '/doctor/appts/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'AppointmentDetail',
    component: AppointmentDetail
  },
  {
    path: '/doctor/contact-detail/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ContactDetail',
    component: ContactDetail
  },
  {
    path: '/doctor/contact-management',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ContactList',
    component: ContactList
  },
  {
    path: '/doctor/diseases/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DiseasesOverview',
    component: DiseasesOverview
  },
  {
    path: '/doctor/diseases/:id/prescription',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DiseasesPrescriptionList',
    component: DiseasesPrescriptionList
  },
  {
    path: '/doctor/diseases/:id/medicine-add',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'MedicineAdd',
    component: MedicineAdd
  },
  {
    path: '/doctor/diseases/:id/document-management',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DocumentManagement',
    component: DocumentManagement
  },
  {
    path: '/doctor/diseases/:id/activity',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DiseasesActivity',
    component: DiseasesActivity
  },
  {
    path: '/doctor/diseases/:id/health-information',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'HealthInformation',
    component: HealthInformation
  },
  {
    path: '/doctor/diseases/:id/health-information/update',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'UpdateHealthInformation',
    component: UpdateHealthInformation
  },
  {
    path: '/doctor/diseases/:id/profile-history',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ProfileHistory',
    component: ProfileHistory
  },
  {
    path: '/doctor/diseases/:id/create',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DiseasesCreate',
    component: DiseasesCreate
  },
  {
    path: '/doctor/diseases/:id/template/:tempid/create',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'CreateDiseaseWithTemplate',
    component: CreateDiseaseWithTemplate
  },
  {
    path: '/doctor/waiting-list',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'WaitingList',
    component: WaitingList
  },
  {
    path: '/doctor/diseases/:id/waiting-profile-details',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'WaitingProfileDetails',
    component: WaitingProfileDetails
  },
  {
    path: '/doctor/diseases/:id/test-create-option',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'TestCreateOptionsPage',
    component: TestCreateOptionsPage
  },
  {
    path: '/doctor/diseases/:id/test-info',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'TestInformationManagement',
    component: TestInformationManagement
  },
  {
    path: '/doctor/diseases/:id/document-echocardiography',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DocumentEchocardiography',
    component: DocumentEchocardiography
  },
  {
    path: '/doctor/diseases/:id/blood-count-test',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'BloodCountTest',
    component: BloodCountTest
  },
  {
    path: '/doctor/diseases/:id/test/:test_id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'TestDetail',
    component: TestDetail
  },
  {
    path: '/doctor/diseases/:id/result/:test_id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ResultDetail',
    component: ResultDetail
  },
  {
    path: '/doctor/diseases/:id/test/create/select-template',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'SelectTemplateTest',
    component: SelectTemplateTest
  },
  {
    path: '/doctor/diseases/:id/medicine-schedules',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'PersonMedicineSchedule',
    component: PersonMedicineSchedule
  },
  {
    path: '/doctor/diseases/:id/topic/create',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'TopicCreate',
    component: TopicCreate
  },
  {
    path: '/doctor/diseases/:id/topic/:topic_id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'TopicDetail',
    component: TopicDetail
  },
  {
    path: '/doctor/diseases/:id/health-information-listing',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DiseasesHealthInformation',
    component: DiseasesHealthInformation
  },
  {
    path: '/doctor/diseases/:id/health-information-detail',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'AnswerListing',
    component: AnswerListing
  },
  {
    path: '/doctor/diseases/:id/call-logs',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DiseasesCallLogs',
    component: DiseasesCallLogs
  },
  {
    path: '/doctor/consultation/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ConsultationUpdate',
    component: ConsultationUpdate
  },
  {
    path: '/doctor/consultation/:id/history',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'LichSuPhieuTuVan',
    component: LichSuPhieuTuVan
  },
  {
    path: '/doctor/consultation-create',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ConsultationCreate',
    component: ConsultationCreate
  },
  {
    path: '/doctor/appointment-create',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'CreateAppointment',
    component: CreateAppointment
  },
  {
    path: '/doctor/appointment-update/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'UpdateAppointment',
    component: UpdateAppointment
  },
  {
    path: '/doctor/diseases/:id/todo-list',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'TodoList',
    component: TodoList
  },
  {
    path: '/doctor/diseases/:id/heart-rate',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'HeartRate',
    component: () => import('@/pages/Diseases/HeartRate.vue')
  },
  {
    path: '/doctor/diseases-templates/:id/clone',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DoctorTemplateClone',
    component: TemplateClone
  },
  // {
  //   path: '/doctor/twilio-rooms',
  //   meta: {layout: 'doctor', requiredInfo: true, requiredWs: true, public: false},
  //   name: 'TwilioListRoom',
  //   component: TwilioListRoom
  // },
  {
    path: '/meeting',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'Meeting',
    component: Meeting
  },
  {
    path: '/organization/livestream',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'Livestream',
    component: Livestream
  },
  {
    path: '/hoi-chan',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ListMdt',
    component: ListMdt
  },
  {
    path: '/tao-hoi-chan',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'CreateMdt',
    component: CreateMdt
  },
  {
    path: '/hoi-chan/:name',
    meta: {
      layout: 'doctor-mdt',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MdtRoom',
    component: MdtRoom
  },
  {
    path: '/doctor/mdt-room/:room_id/disease/:pd_id',
    meta: {
      layout: 'doctor-mdt',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'DiseaseDetailForMdt',
    component: DiseaseDetailForMdt
  },
  {
    path: '/doctor/mdt-room/:room_id/disease-create',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CreateMdtDisease',
    component: CreateMdtDisease
  },
  {
    path: '/doctor/mdt-disease/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MdtDiseaseDetail',
    component: MdtDiseaseDetail
  },
  {
    path: '/doctor/mdt-disease/:id/update',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'UpdateMdtDisease',
    component: UpdateMdtDisease
  },
  {
    path: '/doctor/mdt-disease/:id/document',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MDTDDocument',
    component: MDTDDocument
  },
  {
    path: '/doctor/mdt-disease/:id/pacs',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MdtDiseasePacs',
    component: MdtDiseasePacs
  },
  {
    path: '/clinic-settings',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicInfoManage',
    component: ClinicInfoManage
  },
  {
    path: '/clinic-banks',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicBankManage',
    component: ClinicBankManage
  },
  {
    path: '/ws-mgmt',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicPersonnelManage',
    component: ClinicPersonnelManage
  },
  {
    path: '/ws-mgmt/consulting-rooms',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicConsultingRooms',
    component: ClinicConsultingRooms
  },
  {
    path: '/ws-mgmt/deposit-points',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'DepositPointsWS',
    component: () => import('../components/Doctor/DepositPointsWS/DepositPointsWS.vue')
  },
  {
    path: '/ws-mgmt/deposit-doctors',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'DepositForDoctorsWS',
    component: () => import('../components/Doctor/DepositPointsWS/DepositForDoctorsWS.vue')
  },
  {
    path: '/ws-mgmt/deposit-detail',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'DepositDetailWS',
    component: () => import('../components/Doctor/DepositPointsWS/DepositDetailWS.vue')
  },
  {
    path: '/clinic-disease-template',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicDiseaseTempManage',
    component: ClinicDiseaseTempManage
  },
  {
    path: '/concluding',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ConcludingList',
    component: ConcludingList
  },
  {
    path: '/clinic-stats',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicStatsManage',
    component: ClinicStatsManage
  },
  {
    path: '/doctor/patient-diseases',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'PersonDiseasesManagement',
    component: PersonDiseasesManagement
  },
  {
    path: '/emr-version/:id(\\d+)',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'EMRVersion',
    component: EMRVersion
  },
  {
    path: '/electronic-medical-records/:id(\\d+)',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ElectronicMedicalRecords',
    component: ElectronicMedicalRecords
  },
  {
    path: '/electronic-medical-records/:id(\\d+)/tree-view',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ERMTreeView',
    component: ERMTreeView
  },
  {
    path: '/electronic-medical-records/:id(\\d+)/map-view',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ERMMapView',
    component: ERMMapView
  },
  {
    path: '/clinic-tariff',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ClinicTariffManage',
    component: ClinicTariffManage
  },
  {
    path: '/doctor/create-meeting',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CreateMeet',
    component: CreateMeet
  },
  {
    path: '/meet-room/:id/attachments',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MeetRoomAttachments',
    component: MeetRoomAttachments
  },
  {
    path: '/chat',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ChatPage',
    component: ChatPage
  },
  {
    path: '/chatv2',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ChatPageV2',
    component: () => import('../pages/Chats/ChatV2.vue')
  },
  {
    path: '/circle',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleList',
    component: CircleList
  },
  {
    path: '/circle/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleDetail',
    component: () => import('../pages/CircleV2/CircleDetailV2/index.vue')
  },
  {
    path: '/live-circle/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'LiveCircleDetail',
    component: LiveCircleDetail
  },
  {
    path: '/circle/:id/documents',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleDocuments',
    component: CircleDocumentsV2
  },
  {
    path: '/circle/:id/documents/folder/:folder',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleDocumentsFolder',
    component: CircleDocumentsV2
  },
  {
    path: '/circle/:id/chat',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleChat',
    component: CircleChat
  },
  {
    path: '/mdt-room/:id/update',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MdtRoomUpdate',
    component: MdtRoomUpdate
  },
  {
    path: '/meet-room/:id/update',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MeetRoomUpdate',
    component: MeetRoomUpdate
  },
  {
    path: '/mdt-session/:id/show',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'SessionDetail',
    component: SessionDetail
  },
  {
    path: '/mdt-room/:id/cases/:type/finished',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'MdtListDisease',
    component: MdtListDisease
  },

  // Organization
  {
    path: '/organization',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'Organization',
    component: () => import('../pages/Organization/OrganizationListMdt')
  },
  {
    path: '/organization/management',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationManagement',
    component: () => import('../pages/Organization/OrganizationManagement')
  },
  {
    path: '/organization/management/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationDetail',
    component: () => import('../pages/Organization/OrganizationDetail')
  },
  {
    path: '/organization/management/:id/update',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationDetailUpdate',
    component: () => import('../pages/Organization/OrganizationDetailUpdate')
  },
  {
    path: '/organization/workspace',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationListWs',
    component: () => import('../pages/Organization/OrganizationListWs')
  },
  {
    path: '/organization/workspace/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationWsStaffManage',
    component: () => import('../pages/Organization/OrganizationWsStaffManage')
  },
  {
    path: '/organization/create-new',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationCreateMtd',
    component: () => import('../pages/Organization/OrganizationCreateMdt')
  },
  {
    path: '/organization/mdt-session/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationSessionDetail',
    component: () => import('../pages/Organization/OrganizationSessionDetail')
  },
  {
    path: '/organization/:id/cases/:type/finished',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationMdtListDisease',
    component: () => import('../pages/Organization/OrganizationMdtListDisease')
  },
  {
    path: '/organization/:id/update',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationMdtRoomUpdate',
    component: () => import('../pages/Organization/OrganizationMdtRoomUpdate')
  },
  {
    path: '/organization/update/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationUpdate',
    component: () => import('../pages/Organization/OrganizationUpdate')
  },
  {
    path: '/organization/mdt-case/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationMdtDiseaseDetail',
    component: () =>
      import('../pages/Organization/OrganizationMdtDiseaseDetail')
  },
  {
    path: '/organization/mdt-case/:id/document',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationMDTDDocument',
    component: () => import('../pages/Organization/OrganizationMDTDDocument')
  },
  {
    path: '/organization/mdt-case/:id/pacs',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationMdtDiseasePacs',
    component: () => import('../pages/Organization/OrganizationMdtDiseasePacs')
  },
  {
    path: '/organization/mdt-case/:id/update',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationUpdateMdtDisease',
    component: () =>
      import('../pages/Organization/OrganizationUpdateMdtDisease')
  },
  {
    path: '/organization/mdt-case/:id/print',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationCasePrinter',
    component: () => import('../pages/Organization/OrganizationCasePrinter')
  },
  {
    path: '/ws/mdt-case/:id/print',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HoiChanCasePrinter',
    component: () => import('../pages/HoiChan/CasePrinter')
  },
  {
    path: '/organization/ecom',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationEcom',
    component: () => import('../pages/Organization/OrganizationEcom.vue')
  },
  {
    path: '/organization/ecom-update',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationEcomUpdate',
    component: () => import('../pages/Organization/OrganizationEcomUpdate.vue')
  },
  {
    path: '/organization/conference-detail/:conf_id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrganizationConferenceDetail',
    component: () => import('../pages/Conference/index.vue')
  },
  {
    path: '/organization/conference-list',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OrgConferenceList',
    component: () => import('../pages/Conference/OrgConferenceList.vue')
  },
  // {
  //   path: "/organization/conference/circle/:id",
  //   meta: {
  //     layout: "organization",
  //     requiredInfo: true,
  //     requiredWs: false,
  //     public: false,
  //   },
  //   name: "OrganizationConferenceCircleDetail",
  //   component: () => import("../pages/Conference/OverviewDetail.vue"),
  // },
  {
    path: '/organization/conference/reports/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ConferenceReportDetail',
    component: () => import('../pages/Conference/ReportDetail.vue')
  },
  {
    path: '/organization/credit/accumulate',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HodoCreditAccumulate',
    component: () => import('../pages/HodoCredit/AccumulatePoints.vue')
  },
  {
    path: '/organization/credit/config',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HodoCreditConfig',
    component: () => import('../pages/HodoCredit/CreditConfig.vue')
  },
  {
    path: '/organization/credit/deposit',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HodoCreditDeposit',
    component: () => import('../pages/HodoCredit/DepositPoints.vue')
  },
  {
    path: '/organization/credit/deposit/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HodoCreditDepositDetail',
    component: () => import('../pages/HodoCredit/CreditComponents/DepositDetail.vue')
  },
  {
    path: '/organization/credit/deposit-doctors',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HodoCreditDepositForDoctors',
    component: () => import('../pages/HodoCredit/CreditComponents/DepositForDoctors.vue')
  },
  {
    path: '/service-management',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ServiceManagement',
    component: () =>
      import('../pages/Organization/ServiceManagement.vue')
  },
  {
    path: '/service-catalog',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ServiceCatalog',
    component: () =>
      import('../pages/Organization/ServiceCatalog.vue')
  },
  {
    path: '/service-catalog/:id',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'EditServiceCatalog',
    component: () =>
      import('../pages/Organization/CreateServiceCatalog.vue')
  },
  {
    path: '/service-catalog/add',
    meta: {
      layout: 'organization',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CreateServiceCatalog',
    component: () =>
      import('../pages/Organization/CreateServiceCatalog.vue')
  },
  {
    path: '/services/add',
    meta: {
      layout: 'doctor',
      requiredInfo: false,
      requiredWs: false,
      public: false
    },
    name: 'CreateServiceLocal',
    component: () =>
      import('../pages/Organization/CreateServiceLocal.vue')
  },
  {
    path: '/services/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: false,
      requiredWs: false,
      public: false
    },
    name: 'EditServiceLocal',
    component: () =>
      import('../pages/Organization/CreateServiceLocal.vue')
  },

  {
    path: '/conference',
    meta: {
      layout: 'doctor',
      requiredInfo: false,
      requiredWs: false,
      public: false
    },
    name: 'WorkspaceConference',
    component: () => import('../pages/Conference/Circle/index.vue')
  },
  {
    path: '/_conference/:code',
    meta: {
      layout: 'doctor',
      requiredInfo: false,
      requiredWs: false,
      public: false
    },
    name: 'WorkspaceConferenceRedirect',
    component: () =>
      import('../pages/Conference/Circle/ConferenceRedirect.vue')
  },
  {
    path: '/conference/list_member',
    meta: {
      layout: 'doctor',
      requiredInfo: false,
      requiredWs: false,
      public: false
    },
    name: 'ConferenceListMember',
    component: () =>
      import('../pages/Conference/Circle/ConferenceListMember.vue')
  },
  {
    path: '/conference/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: false,
      requiredWs: false,
      public: false
    },
    name: 'WorkspaceConferenceDetail',
    component: () => import('../pages/Conference/Circle/CircleDetail.vue')
  },
  {
    path: '/conference/:id/documents',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'WorkspaceConferenceDocument',
    component: () =>
      import('../pages/Conference/WorkspaceConferenceDocument.vue')
  },
  {
    path: '/conference/:id/session/:sessionId',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'WorkspaceConferenceSessionDetail',
    component: () =>
      import('../pages/Conference/Circle/ConferenceSessionDetail.vue')
  },
  {
    path: '/conference/:id/documents/folder/:folder',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'WorkspaceConferenceDocumentFolder',
    component: () => `<h2>WorkspaceConferenceDocumentFolder</h2>`
  },
  {
    path: '/ws/indications/:id/print',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CaseIndicationsPrinter',
    component: () =>
      import(
        '../pages/ElectronicMedicalRecords/components/Indications/PagePrinter.vue'
      )
  },
  {
    path: '/appointment/:id/vrc',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ApptCall',
    component: () => import('../pages/VideoCall/ApptCall.vue')
  },
  {
    path: '/profile/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'DoctorProfile',
    component: DoctorProfile
  },
  {
    path: '/electronic-medical-records',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ListBenhAn',
    component: ListBenhAn
  },
  {
    path: '/health-records',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HealthRecords',
    component: HealthRecords
  },
  {
    path: '/health-records/list-patient/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'ListPatientVisitV2',
    component: ListPatientVisitV2
  },
  // {
  //   path: '/health-records/list-patient/:id',
  //   meta: {
  //     layout: 'doctor',
  //     requiredInfo: true,
  //     requiredWs: false,
  //     public: false
  //   },
  //   name: 'ListPatientVisit',
  //   component: ListPatientVisit
  // },
  {
    path: '/health-records/list-patient/detail/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'DetailPatientVisitV2',
    component: DetailPatientVisitV2
  },
  // {
  //   path: '/health-records/list-patient/detail/:id',
  //   meta: {
  //     layout: 'doctor',
  //     requiredInfo: true,
  //     requiredWs: false,
  //     public: false
  //   },
  //   name: 'DetailPatientVisit',
  //   component: DetailPatientVisit
  // },
  {
    path: '/health-record/:id/med',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'HealthRecordMed',
    component: () => import('../pages/HealthRecordMed/index.vue')
  },
  {
    path: '/ba-setting',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'SettingBenhAn',
    component: SettingBenhAn
  },
  {
    path: '/ws-patients',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'WorkspacePatientList',
    component: () => import('../pages/Workspace/PatientList.vue')
  },
  {
    path: '/patient-detail/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'PatientDetail',
    component: () => import('../pages/Workspace/PatientDetail.vue')
  },
  {
    path: '/mr-state/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'MRSate',
    component: () => import('../pages/BenhAn/MRSate.vue')
  },
  {
    path: '/print/indications-ex-result/:id',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'IndicationResultPrintTemplate',
    component: () =>
      import(
        '../pages/ElectronicMedicalRecords/components/Indications/IndicationResultPrintTemplate.vue'
      )
  },

  {
    path: '/services',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'ListService',
    component: () => import('../pages/Service/ListServiceTemplate.vue')
  },
  {
    path: '/emr-template',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'EmrList',
    component: () => import('../pages/EmrTemplate/ListEmrTemplate.vue')
  },
  {
    path: '/emr-template/:id(\\d+)',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'EmrTemplateDetail',
    component: () => import('../pages/EmrTemplate/EmrTemplateDetail.vue')
  },
  {
    path: '/mdt-room/:id(\\d+)/operation-print',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'OperationPrint',
    component: OperationPrint
  },
  {
    path: '/print-content',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'PrintOnyContent',
    component: () => import('../pages/PrintOnlyContent/index.vue')
  },
  {
    path: '/health-record-overview',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'HealthRecordOverview',
    component: () => import('../pages/HealthRecordOverview/index.vue')
  },
  {
    path: '/health-record-overview/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: true,
      public: false
    },
    name: 'HealthRecordOverviewDetail',
    component: () =>
      import('../pages/HealthRecordOverview/HealthRecordOverviewDetail.vue')
  },
  {
    path: '/circle/session/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleSessionDetail',
    component: () =>
      import('../pages/Circle/CircleSessionDetail.vue')
  },
  {
    path: '/circle/:id/live-stream',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleLiveDetail',
    component: () =>
      import('../pages/CircleV2/CircleDetailV2/CircleLiveDetail.vue')
  },
  {
    path: '/circle/:id/conference',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleConferenceDetail',
    component: () =>
      import('../pages/CircleV2/CircleDetailV2/CircleConferenceDetail.vue')
  },
  {
    path: '/circle/:id/topics',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleQAndA',
    component: () =>
      import('../pages/CircleV2/CircleQAndA/index.vue')
  },
  {
    path: '/circle/:id/topics/add',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'AddTopic',
    component: () =>
      import('../pages/CircleV2/CircleQAndA/AddTopic.vue')
  },
  {
    path: '/circle/:id/topics/:topic_id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'TopicDetail',
    component: () =>
      import('../pages/CircleV2/CircleQAndA/TopicDetail.vue')
  },
  {
    path: '/emr-wrapper/:id',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'EMRWrapper',
    component: () =>
      import('../pages/BenhAn/WrapperBenhAn.vue')
  },
  {
    path: '/indication-receipt-detail/:id',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'IndicationReceiptDetail',
    component: () =>
      import('../components/Indications/V2/IndicationDetail.vue')
  },
  {
    path: '/payment-response/:id',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'PaymentResponse',
    component: () =>
      import('../pages/PaymentResponse/index.vue')
  },
  {
    path: '/payment-response-multiples',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'PaymentResponse',
    component: () =>
      import('../pages/PaymentResponse/PaymentResponseMultiples.vue')
  },
  {
    path: '/payment-management',
    meta: {
      layout: 'doctor',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'PaymentManagement',
    component: () =>
      import('../pages/PaymentManagement/index.vue')
  },
  {
    path: '/sign-envelope/:id',
    meta: {
      layout: 'empty',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'SignEnvelope',
    component: () =>
      import('../pages/SignEnvelope/index.vue')
  },

  // {
  //   path: '/tree-family',
  //   meta: {
  //     layout: 'doctor',
  //     requiredInfo: true,
  //     requiredWs: false,
  //     public: false
  //   },
  //   name: 'TreeFamily',
  //   component: () =>
  //     import('../pages/TreeFamily/index.vue')
  // },
  // ------------------------------------------/doctor --------------------------------------

  // ------------------------------------------/admin --------------------------------------
  {
    path: '/admin/diseases/:id/advices',
    meta: { layout: 'admin', public: false },
    name: 'DiseaseAdvices',
    component: DiseaseAdvices
  },
  {
    path: '/admin/diseases-templates',
    meta: { layout: 'admin', public: false },
    name: 'DiseaseTemplate',
    component: TemplateList
  },
  {
    path: '/admin/operation',
    meta: { layout: 'admin', public: false },
    name: 'kycPage',
    component: OperationList
  },
  {
    path: '/admin/hodo-member',
    meta: { layout: 'admin', public: false },
    name: 'HodoMember',
    component: HodoMember
  },
  {
    path: '/admin/diseases-templates-create',
    meta: { layout: 'admin', public: false },
    name: 'DiseaseTemplateCreate',
    component: TemplateCreate
  },
  {
    path: '/admin/diseases-templates/:id/show',
    meta: { layout: 'admin', public: false },
    name: 'DiseaseTemplateDetail',
    component: TemplateDetail
  },
  {
    path: '/admin/survival-stats',
    meta: { layout: 'admin', public: false },
    name: 'StatsList',
    component: StatsList
  },
  {
    path: '/admin/packages',
    meta: { layout: 'admin', public: false },
    name: 'PackagesManagement',
    component: PackagesManagement
  },
  {
    path: '/admin/package-combos',
    meta: { layout: 'admin', public: false },
    name: 'ComboManagement',
    component: ComboManagement
  },
  {
    path: '/admin/package-benefits',
    meta: { layout: 'admin', public: false },
    name: 'MemberPackageBenefit',
    component: MemberPackageBenefit
  },
  {
    path: '/admin/vouchers',
    meta: { layout: 'admin', public: false },
    name: 'VoucherManagement',
    component: VoucherManagement
  },
  {
    path: '/admin/news-categories',
    meta: { layout: 'admin', public: false },
    name: 'NewCategoryListing',
    component: NewCategoryListing
  },
  {
    path: '/admin/newspaper',
    meta: { layout: 'admin', public: false },
    name: 'NewspaperListing',
    component: NewspaperListing
  },
  {
    path: '/admin/management',
    meta: { layout: 'admin', public: false },
    name: 'CmsInformation',
    component: CmsInformation
  },
  {
    path: '/admin/diseases/:id/profile-history',
    meta: { layout: 'admin', public: false },
    name: 'ProfileHistoryAdmin',
    component: ProfileHistory
  },
  {
    path: '/admin/service',
    meta: { layout: 'admin', public: false },
    name: 'ServiceListing',
    component: ServiceListing
  },
  {
    path: '/admin/service-category',
    meta: { layout: 'admin', public: false },
    name: 'ServiceCategoryListing',
    component: ServiceCategoryListing
  },
  {
    path: '/admin/diseases/:id/health-information-listing',
    meta: { layout: 'admin', public: false },
    name: 'CMSDiseasesHealthInformation',
    component: CMSDiseasesHealthInformation
  },
  {
    path: '/admin/diseases/:id/health-information-detail',
    meta: { layout: 'admin', public: false },
    name: 'CMSAnswerListing',
    component: CMSAnswerListing
  },
  {
    path: '/admin/diseases/:id/health-information',
    meta: { layout: 'admin', public: false },
    name: 'CMSHealthInformation',
    component: CMSHealthInformation
  },
  {
    path: '/admin/diseases/:id/topic-detail',
    meta: { layout: 'admin', public: false },
    name: 'CMSTopicDetail',
    component: CMSTopicDetail
  },
  {
    path: '/admin/diseases/:id/topic',
    meta: { layout: 'admin', public: false },
    name: 'TopicListing',
    component: TopicListing
  },
  {
    path: '/admin/diseases/:id/document',
    meta: { layout: 'admin', public: false },
    name: 'CMSDocumentCareplanListing',
    component: DocumentCareplanListing
  },
  {
    path: '/admin/diseases/:id/document',
    meta: { layout: 'admin', public: false },
    name: 'DocumentCareplanListing',
    component: DocumentCareplanListing
  },
  {
    path: '/admin/diseases/:id/medicine-schedules',
    meta: { layout: 'admin', public: false },
    name: 'CMSPersonMedicineSchedule',
    component: PersonMedicineSchedule
  },
  {
    path: '/admin/order',
    meta: { layout: 'admin', public: false },
    name: 'ServicesOrders',
    component: ServicesOrders
  },
  {
    path: '/admin/diseases/:id/detail',
    meta: { layout: 'admin', public: false },
    name: 'TestInformationListing',
    component: TestInformationListing
  },
  {
    path: '/admin/diseases/:id/add',
    meta: { layout: 'admin', public: false },
    name: 'TestInformation',
    component: TestInformation
  },
  {
    path: '/admin/test/:id/detail',
    meta: { layout: 'admin', public: false },
    name: 'TestInformationDetail',
    component: TestInformationDetail
  },
  {
    path: '/admin/diseases/:id/medicine',
    meta: { layout: 'admin', public: false },
    name: 'MedicineCarePlan',
    component: MedicineCarePlan
  },
  {
    path: '/admin/diseases/:id/medicine-add',
    meta: { layout: 'admin', public: false },
    name: 'MedicineAddCarePlan',
    component: MedicineAddCarePlan
  },
  {
    path: '/admin/diseases',
    meta: { layout: 'admin', public: false },
    name: 'diseasesListing',
    component: diseasesListing
  },
  {
    path: '/admin/symptom-category',
    meta: { layout: 'admin', public: false },
    name: 'symptomCategoryListing',
    component: symptomCategoryListing
  },
  {
    path: '/admin/care-plan',
    meta: { layout: 'admin', public: false },
    name: 'CareplanListing',
    component: CareplanListing
  },
  {
    path: '/admin/medicine-list',
    meta: { layout: 'admin', public: false },
    name: 'MedicineListing',
    component: MedicineListing
  },
  {
    path: '/admin/tags',
    meta: { layout: 'admin', public: false },
    name: 'tagsListing',
    component: tagsListing
  },
  {
    path: '/admin/person/:id/detail',
    meta: { layout: 'admin', public: false },
    name: 'ADContactDetail',
    component: ContactDetail
  },
  {
    path: '/admin/contact-list',
    meta: { layout: 'admin', public: false },
    name: 'ContactListAdmin',
    component: ContactListAdmin
  },
  {
    path: '/admin/request-list',
    meta: { layout: 'admin', public: false },
    name: 'RequestListAdmin',
    component: RequestListAdmin
  },
  {
    path: '/admin/send-message',
    meta: { layout: 'admin', public: false },
    name: 'sendMessagesInApp',
    component: sendMessagesInApp
  },
  {
    path: '/admin/question',
    meta: { layout: 'admin', public: false },
    name: 'questionListing',
    component: questionListing
  },
  {
    path: '/admin/invoice',
    meta: { layout: 'admin', public: false },
    name: 'InvoiceListing',
    component: InvoiceListing
  },
  {
    path: '/admin/bank-account',
    meta: { layout: 'admin', public: false },
    name: 'bankAccountListing',
    component: bankAccountListing
  },
  {
    path: '/admin/persons',
    meta: { layout: 'admin', public: false },
    name: 'PersonAdminListing',
    component: PersonAdminListing
  },
  {
    path: '/admin/permisions',
    meta: { layout: 'admin', public: false },
    name: 'permisionsListing',
    component: permisionsListing
  },
  {
    path: '/admin/group-question',
    meta: { layout: 'admin', public: false },
    name: 'GroupQuestion',
    component: GroupQuestion
  },
  {
    path: '/admin/doctors',
    meta: { layout: 'admin', public: false },
    name: 'AdminDoctorListing',
    component: AdminDoctorListing
  },
  {
    path: '/admin/templates',
    meta: { layout: 'admin', public: false },
    name: 'AdminTemplateListing',
    component: AdminTemplateListing
  },
  {
    path: '/admin/templates/add',
    meta: { layout: 'admin', public: false },
    name: 'AdminTemplateAdd',
    component: AdminTemplateAdd
  },
  {
    path: '/clinics',
    meta: { layout: 'admin', public: false },
    name: 'ClinicListing',
    component: ClinicListing
  },
  {
    path: '/applies',
    meta: { layout: 'admin', public: false },
    name: 'ClinicAppliesListing',
    component: ClinicAppliesListing
  },
  {
    path: '/clinics/:id/edit',
    meta: { layout: 'admin', public: false },
    name: 'ClinicEdit',
    component: ClinicEdit
  },
  {
    path: '/clinics/add',
    meta: { layout: 'admin', public: false },
    name: 'ClinicAdd',
    component: ClinicAdd
  },
  {
    path: '/orders/member-package',
    meta: { layout: 'admin', public: false },
    name: 'MemberPackOrders',
    component: MemberPackOrders
  },
  {
    path: '/admin/diseases/:id/call-logs',
    meta: { layout: 'admin', public: false },
    name: 'AdminDiseaseCallLogs',
    component: DiseaseCallLogs
  },
  {
    path: '/admin/diseases/:id/pacs',
    meta: { layout: 'admin', public: false },
    name: 'DiseasePacs',
    component: DiseasePacs
  },
  {
    path: '/admin/pacs',
    meta: { layout: 'admin', public: false },
    name: 'ListPacs',
    component: ListPacs
  },
  {
    path: '/admin/product',
    meta: { layout: 'admin', public: false },
    name: 'ProductManage',
    component: ProductManage
  },
  {
    path: '/admin/kw',
    meta: { layout: 'admin', public: false },
    name: 'KeyManage',
    component: KeyManage
  },
  {
    path: '/admin/patient-states',
    meta: { layout: 'admin', public: false },
    name: 'PatientState',
    component: () => import('../pages/Admin/PatientState.vue')
  },
  // Detail patient
  {
    path: '/doctor/patient-visit/:id/',
    meta: { layout: 'doctor', public: false },
    name: 'PatientVisitDetail',
    component: PatientVisitDetail
  },
  {
    path: '/doctor/work-schedules',
    meta: { layout: 'doctor', public: false },
    name: 'WorkSchedules',
    component: WorkSchedules
  },
  // {
  //   path: '/circle/:id/streaming/:streamId',
  //   meta: { layout: 'doctor', public: false },
  //   name: 'LiveStream',
  //   component: () => import('../pages/100ms/LiveStream.vue')
  // },
  // {
  //   path: '/admin/person-test-template',
  //   meta: {layout: 'admin', public: false},
  //   name: 'PersonTestTemplate',
  //   component: PersonTestTemplate
  // }
  // ------------------------------------------/admin --------------------------------------
  {
    path: '/conference-tutorial/:code',
    meta: { layout: 'empty', public: true },
    name: 'ConferenceTutorial',
    component: () => import('../pages/Conference/Tutorial.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      behavior: 'smooth',
      x: 0,
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  window.$('.modal').modal('hide') // Lỗi modal KhaiNH
  // if the route is not public
  Vue.prototype.$preRoutes.push(from.name)
  Vue.prototype.$preRoutes = Vue.prototype.$preRoutes.slice(-5)
  if (!to.meta.public) {
    // if the user authenticated
    if (!appUtils.getLocalToken() && !to.query.token) {
      // set redirect to path after login
      let redirect = {}
      if (to.name !== 'HomePage') {
        redirect = { redirect: to.path }
      }
      // redirect to login
      next({ name: 'LoginPage', query: redirect })
    }
  }
  next()
})

export default router
